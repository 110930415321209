<template>
  <b-container class="bg-dark fontStandard">
    <b-form>
      <b-row>
        <!--  -->
        <b-col class="mx-auto text-white" cols="12">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>URL</b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_url"
                class="form-control"
                placeholder="Entrer l'url de destination"
                type="text"
                v-model="supervisor.url"
                maxlength="512"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Foreground</b-input-group-text>
              </b-input-group-prepend>
              <b-input-group-prepend>
                <b-input-group-text
                  :style="{ background: supervisor.foreground }"
                ></b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_foreground"
                class="form-control"
                placeholder="Couleur de premier plan"
                type="text"
                v-model="supervisor.foreground"
                maxlength="25"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="text-truncate"
                  >Background</b-input-group-text
                >
              </b-input-group-prepend>
              <b-input-group-prepend>
                <b-input-group-text
                  :style="{ background: supervisor.background }"
                ></b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_background"
                class="form-control"
                placeholder="Couleur d'arrière plan"
                type="text"
                v-model="supervisor.background"
                maxlength="25"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Qualité</b-input-group-text>
              </b-input-group-prepend>
              <select class="form-control" v-model="supervisor.quality">
                <option value="L">Basse</option>
                <option value="M">Moyenne</option>
                <option value="Q">Bonne</option>
                <option value="H">Haute</option>
              </select>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-button
              type="submit"
              variant="primary"
              @click="add_qr_code"
              block
              :disabled="isIncorrectForm"
            >
              <i class="fas fa-plus" /> Créer
            </b-button>
          </b-form-group>

          <b-form-group>
            <b-button
              type="submit"
              variant="danger"
              @click="$root.$emit('bv::hide::modal', 'add-modal')"
              block
            >
              <i class="fas fa-times" /> Fermer cette fenetre
            </b-button>
          </b-form-group>

          <QrCode
            :value="supervisor.url"
            :color="supervisor.foreground"
            image="png"
            :background="supervisor.background"
            className=" text-center"
            :size="350"
            :quality="supervisor.quality"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    QrCode: () => import("@/components/QrCode"),
  },
  mixins: [Form],
  data() {
    return {
      supervisor: {
        code: "https://www.google.fr/",
        url: "",
        foreground: "#33aa00",
        background: "transparent",
        quality: "Q",
      },
    };
  },
  beforeDestroy() {
    this.supervisor = undefined;
  },
  computed: {
    ...mapState(["account", "axios"]),
    ...mapGetters([]),
    isIncorrectForm() {
      return this.supervisor.url.length > 7 &&
        this.supervisor.foreground.length > 1 &&
        this.supervisor.background.length > 1
        ? false
        : true;
    },
  },
  methods: {
    async add_qr_code(e) {
      e.preventDefault();

      const Obj = {
        id: this.supervisor.id,
        url: this.supervisor.url,
        foreground: this.supervisor.foreground,
        background: this.supervisor.background,
        quality: this.supervisor.quality,
      };

      await this.$root.axios("post", "qr/codes/add", Obj);

      this.completeCommit();
    },
    completeCommit() {
      const axios = this.account.axios_data;

      if (
        axios &&
        axios.api_response === "successfull_add" &&
        axios.check_input.length === 0
      ) {
        this.$root.showToast(`Nouveau`, `Nouveau QR CODE`, "info", 2000);

        this.$root.$emit("bv::hide::modal", "add-modal");
        // this.$parent.$parent.$parent.$refs.table.refresh();
        // TO DO recharger les données dans le modal ?
      }
    },
  },
};
</script>
